import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  components: {
    DatePicker: {
      variants: {
        solid: {
          container: {
            borderColor: 'gray.200',
          },
        },
      },
      defaultProps: {
        locale: 'pt-BR', 
      },
    },
    Button: {
      variants: {
        primary: {
          bg: "blue.200",
          color: "white",
          borderRadius: "2px",
          _hover: {
            filter: "brightness(.9)",
          },
        },
        secondary: {
          bg: "transparent",
          color: "black",
          borderRadius: "2px",
          border: "1px solid",
          borderColor: " #D9D9D9",
          transition: "background 0.4s ease",
          _hover: {
            bg: "#e3e5e8",
          },
        },
        white: {
          bg: "white",
          fontWeigth: 400,
          border: "1px solid #D9D9D9",
          borderRadius: "2px",
          padding: "0",
        },
        green: {
          bg: "green.300",
          color: "white",
          padding: "0.5rem",
          transition: "filter 0.4s ease",
          borderRadius: "2px",
          _hover: {
            filter: "brightness(.9)",
          },
          _disabled: {
            bg: "gray.50",
            color: "gray.500",
            borderRadius: "2px",
            _hover: {
              bg: "gray.50",
            },
          },
        },
        cancel: {
          bg: "transparent",
          color: "black",
          borderRadius: "2px",
          border: "1px solid",
          borderColor: "gray.200",
          fontWeight: 400,
          transition: "background 0.4s ease",
          _hover: {
            transition: "filter 0.1s",
            filter: "invert(50%)",
          },
          _active: {
            filter: "invert(50%)",
          },
        },
        "cancel.red": {
          bg: "transparent",
          color: "#FF4D4F",
          borderRadius: "2px",
          border: "1px solid",
          borderColor: " #FF4D4F",
          fontWeight: 400,
          transition: "background 0.4s ease",
          _hover: {
            bg: "#ffbaba",
          },
        },
      },
    },
    Badge: {
      baseStyle: {
        textTransform: "none",
        fontWeight: 400,
      },
      variants: {
        blueBills: {
          bg: "rgba(8, 55, 145, 0.15)",
          color: "rgba(6, 17, 120, 1)",
          border: "1px solid rgba(6, 17, 120, 1)",
          p: "0 0.5rem",
        },
        yellowBills:{
          bg: "rgba(254, 255, 230, 1)",
          color: "rgba(97, 71, 0, 1)",
          border: "1px solid rgba(255, 236, 61, 1)",
          p: "0.3rem 0.3rem",
        },
        orangeBills:{
          bg: "rgba(255, 247, 230, 1)",
          color: "rgba(130, 0, 20, 1)",
          border: "1px solid rgba(255, 192, 105, 1)",
          p: "0 0.5rem",
        },
        redBills:{
          bg: "rgba(255, 241, 240, 1)",
          color: "rgba(135, 56, 0, 1)",
          border: "1px solid rgba(255, 120, 117, 1)",
          p: "0 0.5rem",
        },
        greenBills:{
          bg: "rgba(246, 255, 237, 1)",
          color: "rgba(19, 82, 0, 1)",
          border: "1px solid rgba(149, 222, 100, 1)",
          p: "0 0.5rem",
        },
        blue: {
          bg: "rgba(8, 55, 145, 0.15)",
          color: "blue.400",
          p: "0 0.5rem",
        },
        gray: {
          bg: "#E6E9F0",
          color: "#3D485C",
          p: "0 0.5rem",
        },
        green: {
          bg: "rgba(44, 196, 82, 0.1)",
          color: "green.400",
          p: "0 0.5rem",
        },
        lightgreen: {
          bg: "rgba(193, 211, 205, 0.5)",
          color: "green.400",
          p: "0 0.5rem",
        },
        yellow: {
          bg: "rgba(240, 188, 3, 0.1)",
          color: "yellow.dark",
        },
        red: {
          bg: "rgba(196, 44, 44, 0.1)",
          color: "red.700",
          p: "0 0.5rem",
        },
        lightred: {
          bg: "rgba(255, 68, 68, 0.1)",
          color: "#3D485C",
          p: "0 0.5rem",
        },
        volcano: {
          bg: "volcano",
          color: "orange.10",
          p: "0 0.5rem",
        },
        yellowDark: {
          bg: "#FFE7BA",
          color: "#3D485C",
          p: "0 0.5rem",
        },
        lightPink: {
          bg: "#FFF0F6",
          color: "#C41D7F",
          p: "0 0.5rem"
        },
        lightGray: {
          bg: "#F4F7FB",
          color: "#6E7A91",
          p: "0 0.5rem"
        }
      },
    },
  },
  colors: {
    newGray: {
      "100": "#E6E9F0",
      "200": "#C3CAD7",
      "300": "#A5AEC0",
      "400": "#8A94A8",
      "500": "#6F7B90",
      "600": "#556077",
    },
    gray: {
      "10": "#FBFCFE",
      "24": "#F4F7FB",
      "25": "#F5F7FA",
      "49": "#EDF1F7",
      "50": "#dfe3ec",
      "100": "#e3e5e8",
      "200": "#c8cbd2",
      "300": "#aab0bb",
      "400": "#8d95a5",
      "500": "#707b8f",
      "600": "#5a6272",
      "700": "#434a56",
      "800": "#2c313a",
      "900": "#16181d",
      login: "#262F40",
    },
    yellow: {
      "100": "#FFF7E6",
      dark: "#665720",
    },
    neutral: {
      "3": "#F5F5F5",
      "100": "#eeeeee",
      "200": "#D9D9D9",
      "300": "#cccccc",
      "600": "#888888",
      "700": "#666666",
      "800": "#282828",
      "900": "#000000",
    },
    black: {
      900: "#000000",
    },
    white: "#ffffff",
    red: {
      "7": "#CF1322",
      500: "#AD2102",
      700: "#8E1E1E",
      danger: "#FF4D4F",
      dust: "#A8071A",
      dustLigth: "#FFF1F0",
    },
    green: {
      "50": "#C1D3CD",
      "200": "#16BE95",
      "300": "#0AA17C",
      "400": "#33594D",
    },
    cobrancaP: {
      700: "#1890FF",
    },
    bgRow: "#f5f5f6",
    success: "#1cedba",
    danger: "#d33939",
    magenta: "#dd057c",
    volcano: "#FFF2E8",
    orange: {
      sunset: "#FFF7E6",
      10: "#873800",
    },
    blue: {
      10: "#E6F7FF",
      25: "#BDE9FE",
      50: "#10AFEE",
      100: "#4cbfed",
      200: "#1890FF",
      400: "#4A6AA8",
      dayBreak: "#002766",
    },
    gold: {
      "7": "#D48806",
      "8": "#AD6800",
      "100": "#FFFBE6",
      "500": "#FAAD14",
    },
    pink: {
      100: "#C21776",
    },
  },
  fonts: {
    heading: "Hero New",
    body: "Hero New",
  },
  styles: {
    global: {
      body: {
        bg: "gray.10",
        color: "gray.600",
        a: {
          color: "#6F7B90",
          _hover: {
            color: "#000",
          },
        },
        table: {
          svg: {
            color: "#6F7B90",
            _hover: {
              color: "#000",
            },
          },
        },
      },
    },
  },
});

export { theme };
