import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Hero New';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: local('Hero New'), url(/fonts/HeroNewRegular.woff) format('woff');
      }
      @font-face {
        font-family: 'Hero New';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: local('Hero New'), url(/fonts/HeroNewSemiBold.woff) format('woff');
      }
      @font-face {
        font-family: 'Hero New';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: local('Hero New'), url(/fonts/HeroNewBold.woff) format('woff');
      }
      `}
  />
)

export default Fonts